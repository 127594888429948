*{
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}
.f-100{
    font-weight: 100;
}
.f-200{
    font-weight: 200;
}
.f-300{
    font-weight: 300;
}
.f-400{
    font-weight: 400;
}
.f-500{
    font-weight: 500;
}
.f-600{
    font-weight: 600;
}
.f10{
  font-size: 10px;
}
.f12{
    font-size: 12px;
}
.f14{
    font-size: 14px;
}
.f16{
    font-size: 16px;
}
.f18{
    font-size: 18px;
}
.f20{
    font-size: 20px;
}
.f22{
    font-size: 22px;
}
.f24{
    font-size: 24px;
}
.tc1{
    color: #2b2e30;
}
.tc2{
    color: #414445;
}
.tc3{
    color: #55595a;
}
.tc4{
    color: #838789;
}
.tc5{
  color: #b5b9bb;
}
.c-white{
    color: #FFF;
}
.br1{
    border-radius: 1px;
}
.br2{
    border-radius: 2px;
}
.br3{
    border-radius: 3px;
}
.br4{
    border-radius: 4px;
}
.br5{
    border-radius: 5px;
}
.bdr-1{
  border: 1px solid #e4e4e4;
}
.bdr-1l{
  border-left: 1px solid #e4e4e4;
}
.bdr-1t{
  border-top: 1px solid #e4e4e4;
}
.bdr-1r{
  border-right: 1px solid #e4e4e4;
}
.bdr-1b{
  border-bottom: 1px solid #e4e4e4;
}
.just-start{
  justify-content: start;
}
.just-center{
  justify-content: center;
}
.just-between{
  justify-content: space-between;
}
.just-end{
  justify-content: end;
}
.al-start{
  align-items: start;
}
.al-center{
  align-items: center;
}
.al-end{
  align-items: end;
}
.please-wait-spinner > .spinner-border{
  border-width: 2px;
}
.error-message{
  font-size: 14px;
  background-color: #f8d7da;
  padding:4px 8px;
  border: 1px solid #f5c2c7;
  border-radius: 2px;
  color: red;
}
.error-text{
  color: red;
}
.success-message{
    color: green;
}
.sucess-text{
  color: green;
}
.sucess-tag{
  color: green;
  background-color: #20c99733;
  border: 1px solid #20c997;
}
.error-tag{
  color: #E3000F;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
}
.please-wait-spinner > .spinner-border{
  border-width: 2px;
}
.bg-1{
  background-color: #F7F5EB;
}
.pos-rel{
  position: relative;
}
.stat-green{
  background-color: #76eaafa6;
  border: 1px solid #3dc08d;
}
.stat-oranage{
    background-color: #ffa8548c;
    border: 1px solid #d38000;
}
.stat-blue{
    background-color: #00c2f569;
    border: 1px solid #00bdd4;
}
.stat-red{
  background-color: #ff7a8f6e;
  border: 1px solid #e3000f;
}
.stat-black{
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
}
.img-center{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.cpoint{
    cursor: pointer;
}
.login-wrapper{
  height: 100vh;
  display: grid;
  align-content: center;
  justify-content: center;
}
.login-window{
  border: 1px solid #80B500;
  box-shadow: 1px 1px 3px #00000038;
  width: 450px;
}
.login-form input[type="text"],
.login-form input[type="password"]{
  background-color: #f8faf1;
  border-radius: 3px;
  text-align: center;
}
.login-form input[type="text"]:focus,
.login-form input[type="password"]:focus{
  outline: none;
}
.btn-t1{
/*  background-color: #e9edef;*/
  background-image: linear-gradient(#e9edef, #b8d5e2);
  border: 1px solid #b8d5e2;
  cursor: pointer;
  color: #444749;
  transition: all ease 0.2s;
}
.btn-t1:focus{
  color: #444749;
  outline: none;
}
.btn-t2{
  background-color: #219b5f;
  border: 1px solid #699403;
  cursor: pointer;
  color: #FFF;
  transition: all ease 0.2s;
}
.btn-t1:hover,
.btn-t2:hover{
/*  background-color: #aff6e7;*/
  background-image: linear-gradient(#aff6e7, #7fd1ae);
  border: 1px solid #00c6a9;
  color: #071c1f;
  transition: all ease 0.2s;
}
.btn-disable{
  background-color: #FFF;
  color: #ff8271;
  border: 1px solid #ff8271;
}
.btn-disable:hover{
  background-color: #ff8271;
  color: #FFF;
  border: 1px solid #ff8271;
}
.btn-enable{
  background-color: #FFF;
  color: #00c396;
  border: 1px solid #00c396;
  transition: all ease 0.2s;
}
.btn-enable:hover{
  background-color: #00c396;
  color: #FFF;
  border: 1px solid #00c396;
  transition: all ease 0.2s;
}
.left-sidebar{
  min-height: 100vh;
  border-right: 1px solid #e4e4e4;
}
.sidebar-head{
  display: grid;
  min-height: 50px;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  text-transform: capitalize;
}
.menu-item,
.c-point{
  cursor: pointer;
}
.menu-item.active{
  background-color: #e9edef;
}
.menu-item:hover{
  background-color: #e9edef;
}
.res-menu-btn{
  position: fixed;
  bottom: 10%;
  right: 5%;
  z-index: 9999;
  display: none;
  width: 45px;
  height: 45px;
}
.res-menu{
  position: fixed;
  left: 1%;
  top: 2%;
  bottom: 2%;
  height: 96%;
  width: 80%;
  border-radius: 5px;
  overflow-y: scroll;
  z-index: 9999;
  background-color:#d2fadf ;
  border: 1px solid #219b5f;
  display: none;
  padding: 0;
}
.dash-menu-item{
  cursor: pointer;
  color: #1c2947;
  transition: all .2s ease;
}
.dash-menu-item:hover,
.dash-menu-item.active:hover{
  background-color: #e4e4e4;
}
.dash-menu-item.active{
  background-color: #e9edef;
}
.dash-body-head{
  min-height: 51px;
}
.bg1{
  background-color: #e9edef4f;
}
.bg2{
  background-color: #c8cfd775;
}
.bdr-2{
  border: 1px solid #c8cfd7;
}
.bdr-2b{
  border-bottom: 1px solid #c8cfd7;
}
.tc1{
  color: #444749;
}
.tc2{
  color: #344a53;
}

.perm-item{
  background-color: #f8f8f8;
  padding:  5px 10px;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  cursor: pointer;
}
.perm-item-active{
  background-color: #20c99733;
  padding:  5px 10px;
  border: 1px solid #00b094;
  border-radius: 3px;
  cursor: pointer;
}
.perm-item-active > i{
  color: green;
}
.perm-item > i{
  color: red;
}
.perm-item:hover{
  background-color: #20c99733;;
}
.perm-item-active:hover{
  background-color: #f8f8f8;
}
.size-area{
  display: none;
}
.pro-item{
  background-color: #e7fdff;
  border: 1px solid #a8c9e9;
  border-radius: 3px;
}
.pro-head{
  background-color: #b8eef5;
  border-bottom: 1px solid #a8c9e9;
}
.pro-det-item{
  display: grid;
  grid-template-columns: 150px 10px auto;
  margin-bottom: 8px;
}
.search-results{
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #FFF;
  z-index: 9999;
}
.sresultItem{
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}
.sresultItem:hover{
  background-color: #00b2be29;
}
.sresultItem.active{
  background-color: #00b2be29;
}
@media screen and (max-width: 768px) {
  .res-menu-btn{
    display: block;
  }
  .hide-sm{
    display: none;
  }
  .left-sidebar{
    display: none;
  }
  .sm-mb-3{
    margin-bottom: 1rem;
  }
  .pc-5{
    padding: .5rem;
  }
  .grid-30{
    grid-template-columns: 1fr;
  }
  .h-50{
    height: auto;
  }
}
@media screen and (max-width: 576px) {
  .login-window{
    width: 95%;
    justify-self: center;
    align-self: center;
  }
}